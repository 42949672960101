@import 'antd/dist/reset.css';
@import './reset.scss';


.main {
  height: 100%;
  position: relative;
}

.first-page {
  margin-right: 5px;
}

.last-page {
  margin-left: 5px;
}

.collapse-content {
  padding: 30px 45px;
  background-color: #f3f3f3;
  border-radius: 20px;
}

.ant-collapse-header-text {
  font-weight: bold;
}

.ant-collapse-item {
  border: 1px solid #e5e5e5;

  &:last-child() {
    border-bottom: 1px solid #e5e5e5!important;
    z-index: 1111;
  }
}

.ant-collapse-item-active {
  border-color: #ee0015 !important;
}

.ant-collapse-item-active {

  .ant-collapse-header-text,
  .ant-collapse-arrow {
    color: #ee0015 !important;

  }
}
