.AppFooter {
  height: 130px;
  width: 100%;
  background: #d10b1d;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 130px;
  margin-top: 120px;
  position: absolute;
  bottom: -360px;
  left: 0;
  right: 0;
  width: 100%;
  // animation: slide-up 3s ease forwards;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
