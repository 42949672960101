body,
html,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
header,
menu,
section,
p,
input,
td,
th,
ins {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #333;
}

h2 {
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

img {
  vertical-align: top;
}

ul,
li {
  list-style: none;
}

button {
  outline: none;
}


html::-webkit-scrollbar {
  display: none;
}


.ant-collapse{
  font-size: 22px;
}
