.HeaderWrapper {
  //  width: 1920px;
  height: 130px;
  background-color: #fff;
  color: #333;
  border-bottom: 8px solid #ee0015;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;

  .content {
    display: flex;
    height: 100%;
    width: 80%;
    margin: 0 auto;
    justify-content: space-between;
  }
}

.HeaderLeft {
  display: flex;
  align-items: center;
  height: 100%;
  width: 50%;

  .tilte-logo {
    height: 77px;
    cursor: pointer;
  }

  .logo {
    font-size: 30px;
    margin-left: 10px;
    font-weight: bold;
  }
}

.HeaderRight {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 50px;

  .title-list {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      padding-top: 10px;

      a {
        display: block;
        // padding: 0 40px;
        color: #222222;
        font-weight: bold;
        font-size: 26px;
      }

      &:hover a,
      .active {
        color: #e50015;
        text-decoration: none;
      }

      .active .icon {
        position: absolute;
        display: inline-block;
        width: 28px;
        bottom: 0;
        height: 10px;
        left: 50%;
        transform: translate(-50%, 0);
        background: url("../../assets/images/router-top.jpg") no-repeat;
      }
    }

    .now-date {
      position: absolute;
      top: 65px;
      right: 0;
      font-size: 24px;
      color: #969696;
    }
  }

}
